<template>
  <v-container fluid class="pa-0">

    <!-- App bar -->
    <v-app-bar height="30" dark color="primary" flat>
      <div class="mx-auto" style="font-size: 20px">Mnemonist Software</div>
    </v-app-bar>

    <!-- Header -->
    <Header :title="$t('events.addition')"/>

    <!-- Game -->
    <div id="game" :style="{'width': computedWidth + 'px', 'height': (computedWidth - 60) + 'px'}"
         class="d-flex flex-column flex-nowrap justify-center align-center">
      <!-- Number -->
      <div id="number" v-if="phase === 'watch'">{{ number }}</div>

      <!-- Result -->
      <v-expand-transition>
        <div id="text" v-if="phase === 'result'">{{ $t('warm-up-games.enter-result') + ':' }}</div>
      </v-expand-transition>
      <v-expand-transition>
        <div id="result" v-if="phase === 'result'">
          <v-text-field id="result-text-field" dense outlined hide-details type="number"
                        v-model="result" @keyup.enter="calculateScore()"/>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <v-btn v-if="phase=== 'result'" large color="primary" class="mt-2" @click="calculateScore()">OK</v-btn>
      </v-expand-transition>

      <!-- Score -->
      <v-expand-transition>
        <div id="answer" v-if="['success', 'failure'].includes(phase)">
          {{ phase === 'success' ? $t('warm-up-games.success') : $t('warm-up-games.failure') }}
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div id="score" v-if="['success', 'failure'].includes(phase)">
          <span v-if="phase === 'success'" style="color: #4CAF50">{{ result }}</span>
          <span v-if="phase === 'failure'" style="color: #F44336" class="text-decoration-line-through">
            {{ result }}
          </span><span v-if="phase === 'failure'" style="color: #4CAF50">&nbsp;{{ sum }}</span>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <v-btn v-if="['success', 'failure'].includes(phase)" large color="primary" class="mt-3"
               @click="$router.push('/warm-up/addition')">{{ $t('common.end') }}
        </v-btn>
      </v-expand-transition>

    </div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderWarmUp';

export default {
  name: 'WarmUpAddition',
  components: {Header},
  data: () => ({
    numbers: [], number: null, phase: 'watch', result: null, sum: null
  }),
  computed: {
    computedWidth() {
      return this.$vuetify.breakpoint.width > 330 ? 300 : (this.$vuetify.breakpoint.width - 30);
    },
    preferences() {
      return this.$api.game.preferences;
    }
  },
  created() {
    for (let i = 0; i < this.preferences.number; i++)
      this.numbers.push(1 + Math.floor(Math.random() * this.preferences.range));
    this.sum = this.numbers.reduce((partialSum, v) => partialSum + v, 0);
    setTimeout(() => {
      this.showNumbers();
    }, 1000);
  },
  methods: {
    calculateScore() {
      this.phase = parseInt(this.result) === this.sum ? 'success' : 'failure';
      this.$api.submitGame(0, 0);
    },
    showNumbers(i = 0) {
      document.querySelector('#game #number').classList.add('number-hidden');
      setTimeout(() => {
        this.number = this.numbers[i];
        document.querySelector('#game #number').classList.remove('number-hidden');
        setTimeout(() => {
          if (i + 1 < this.numbers.length)
            this.showNumbers(i + 1);
          else setTimeout(() => this.phase = 'result', 300);
        }, this.preferences.time * 1000);
      }, 300);
    }
  }
}
</script>

<style scoped>
#game {
  background-color: #E0E0E0;
  border: 8px solid #BDBDBD;
  border-radius: 20px;
  margin: 10px auto 0 auto;
  transition-duration: 300ms;
}

#number {
  color: #000000;
  font-size: 100px;
  font-weight: 500;
  transition-duration: 300ms;
}

/*noinspection CssUnusedSymbol*/
.number-hidden {
  font-size: 0 !important;
}

#text {
  color: #000000;
  font-size: 22px;
  font-weight: 500;
  margin: -12px auto 12px auto;
}

#answer {
  font-size: 22px;
  font-weight: 500;
  margin: 8px auto 4px auto;
}

#score {
  font-size: 24px;
  font-weight: 500;
  margin: 4px auto;
}
</style>
